.form {
    padding-right: 86px;

    label {
        &:nth-child(5) {
            margin-bottom: 32px;
        }
    }
}

.finalBtn {
    width: fit-content;
    margin: 32px auto 0 auto;
}