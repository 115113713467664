@import '../../css/vars.global.scss';

.outer {
    &.start {
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        transform: translateX(100%);

        .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 260px;
            height: 100%;
            min-height: 223px;
            padding: 40px 36px;
            text-align: center;
            background-color: $color_titlegreen;

            @media (max-width: 1500px) {
                width: 220px;
                min-height: 200px;
                padding: 40px 30px;
            }
        }
    }

    &.question {
        text-align: center;
    }

    &.height {
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .inner {
            justify-content: center;
        }
    }
}