@import '../../../css/vars.global.scss';

.wrapper {
    padding: 20px 10px 16px 10px;
    text-align: center;
    background-color: $color_darkgray;
    transition: .3s;
    
    &.isHoverable {
        cursor: pointer;
        &:hover {
            background-color: rgba(56, 56, 56, 1);
        }
    }

    &.chosen {
        background-color: $color_titlegreen;
    }

    &.correct {
        background-color: $color_green;
    }

    &.wrong {
        background-color: $color_red;
    }

    &.disabled {
        pointer-events: none;
    }

    &.clicked {
        background-color: #383838;
    }
}