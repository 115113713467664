@import '../../css/vars.global.scss';

.p1 {
    font-size: 28px;
    line-height: 120%;

    @media (max-width: 1500px) {
        font-size: 24px;
    }
}

.p2 {
    font-size: 24px;
    line-height: 120%;

    @media (max-width: 1500px) {
        font-size: 18px;
    }
}

.p3 {
    font-size: 18px;
    line-height: 120%;

    @media (max-width: 1500px) {
        font-size: 16px;
    }
}

.descr {
    font-size: 16px;
    line-height: 120%;

    @media (max-width: 1500px) {
        font-size: 14px;
    }
}

.regular {
    font-weight: 400;
}

.semibold {
    font-weight: 500;
}

.black {
    color: $color_black;
}

.gray {
    color: $color_gray
}

.white {
    color: $color_white;
}

.green {
    color: $color_titlegreen;
}

.margin-32 {
    margin-bottom: 32px;
}

.margin-28 {
    margin-bottom: 28px;

    @media (max-width: 1500px) {
        margin-bottom: 24px;
    }
}

.margin-24 {
    margin-bottom: 24px;

    @media (max-width: 1500px) {
        margin-bottom: 20px;
    }
}

.margin-8 {
    margin-bottom: 8px;
}

.margin-0 {
    margin-bottom: 0;
}