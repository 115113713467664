@import '../../css/vars.global.scss';

.list {
    width: 100%;
    margin: 0 auto;

    &.result {
        max-width: 611px;

        .name {
            flex-grow: 1;
            padding: 0 24px;
        }
    }

    &.glory {
        .item {
            justify-content: space-between;
        }

        .name {
            min-width: 35%;
            max-width: 35%;
            text-align: left;
            padding: 0 24px;
        }

        .info {
            min-width: 50%;
            max-width: 50%;
            padding: 0 24px;
        }

        .points {
            width: calc(100% - 64px - 85%);
        }
    }
}

.item {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 32px;

        @media (max-width: 1500px) {
            margin-bottom: 16px;
        }
    }

    .place {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        height: 60px;
        min-width: 64px;
        max-width: 64px;
        align-items: center;
        text-align: center;
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;
        color: $color_white;

        svg {
            position: absolute;
            width: 60px;
            z-index: -1;
            top: 0;
            left: 0;
        }

        span {
            position: absolute;
            top: 4px;
            left: 0;
            display: flex;
            width: 60px;
            height: 60px;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }

    .name {
        text-align: left;
    }

    .points {
        text-align: right;
    }

    .info {
        display: flex;
        align-items: center;

        p {
            width: 50%;
            text-align: left;
        }
    }
}