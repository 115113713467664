@import '../../css/vars.global.scss';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    min-height: 100vh;
    padding-top: 160px;
    background-color: $color_bg;
    opacity: 0;
    transform: translateY(30%);
    transition: .4s ease-in-out;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: $color_white;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color_green;
        border-radius: 20px;
    }

    &.active {
        opacity: 1;
        transform: none;
    }

    @media (max-width: 1500px) {
        padding-top: 112px;
    }

    .content {
        display: flex;
        justify-content: space-between;
    }

    .questionBlock {
        max-width: 918px;
        width: 100%;
        padding-bottom: 32px;

        @media (max-width: 1500px) {
            max-width: 720px;
        }

        .image {
            position: relative;
            width: 100%;
            height: 328px;
            margin-bottom: 32px;

            @media (max-width: 1500px) {
                height: 270px;
                margin-bottom: 20px;
            }

            img {
                object-fit: cover;
                height: 100%;
            }

            .info {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: flex;
                align-items: center;

                .theme {
                    padding: 20px 32px 16px 32px;
                    background-color: $color_darkgreen;

                    @media (max-width: 1500px) {
                        padding: 18px 24px 14px 24px;
                    }
                }

                .points {
                    padding: 20px 25px 16px 25px;
                    background-color: $color_green;

                    @media (max-width: 1500px) {
                        padding: 18px 22px 14px 22px;
                    }
                }
            }
        }

        .text {
            max-width: 700px;
            margin: 0 auto;
            text-align: center;

            @media (max-width: 1500px) {
                max-width: 504px;
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 672px;
            margin: 0 auto;

            @media (max-width: 1500px) {
                max-width: 496px;
            }
            
            li {
                width: calc(100%/2 - 24px);
            }

            .answerBtn {
                margin-top: 32px;

                @media (max-width: 1500px) {
                    margin-top: 20px;
                }
            }
        }
    }

    .infoBlock {
        display: flex;
        flex-direction: column;
        padding-right: 74px;

        @media (max-width: 1500px) {
            padding-right: 0;
        }

        .rating {
            margin-top: 40px;
            text-align: center;

            .teams {
                width: 321px;

                &Item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    text-align: left;

                    &:not(:last-child) {
                        margin-bottom: 28px;
                    }
                }
            }
        }
    }
}
.activePlayer {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
}