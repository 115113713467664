@import '../../css/vars.global.scss';

.item {
    position: relative;
    display: flex;
    text-align: left;
    flex-direction: column;

    &.text, &.email {
        margin-bottom: 24px;

        input {
            width: 414px;
            padding: 14px 10px;
            font-size: 16px;
            line-height: 19px;
            color: $color_black;
            border: 1px solid $color_lightgrey;
            outline: none;

            &::placeholder {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $color_gray;
            }

            &:active, &:focus {
                border-color: $color_darkgreen;
            }
        }
    }

    &.checkbox {
        margin-bottom: 24px;

        input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            z-index: -1;
            overflow: hidden;

            &+label {
                position: relative;
                padding-left: 60px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    width: 36px;
                    height: 36px;
                    border: 1px solid #74B65F;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 1px;
                    bottom: 0;
                    margin: auto;
                    width: 36px;
                    height: 36px;
                    background: url('../../assets/icons/check.svg') no-repeat center center;
                    opacity: 0;
                    transition: .2s;
                }
            }

            &:checked+label {
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    label {
        display: flex;
    }

    &.error {
        input {
            border-color: $color_red;
        }
    }

    .mistake {
        padding-top: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $color_red;
    }
}