.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 24px;

        @media (max-width: 1500px) {
            margin-bottom: 20px;
        }
    }

    .name, .city, .school {
        min-width: calc(100%/3);
        max-width: calc(100%/3);
        text-align: left;
    }

    .city {
        padding: 0 8px;
    }

    .school {
        padding-left: 8px;
    }
}