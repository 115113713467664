@import '../../../css/vars.global.scss';

.item {
    max-width: 100px;
    width: 100%;
    padding: 20px 20px 16px 20px;
    margin: 2px;
    background-color: $color_lightgrey;
    cursor: pointer;
    text-align: center;

    &.active {
        background-color: $color_green;
    }

    span {
        pointer-events: none;
    }
}