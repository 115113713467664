@import '../../../css/vars.global.scss';

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 24px 80px;
    text-align: center;
    border: 1px solid;
    background-color: $color_white;

    &.correct {
        border-color: $color_darkgreen;
    }

    &.wrong {
        border-color: $color_red;
    }
}