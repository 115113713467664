$color_white: #fff;
$color_black: #000000;
$color_bg: #F7F7F7;
$color_gray: #707A83;
$color_darkgray: #565D64;
$color_mgray: #7B8690;
$color_lightgrey: #C4C4C4; 
$color_vlightgrey: #E0E0E0; 
$color_green: #74B65F;
$color_titlegreen: #46A52B;
$color_darkgreen: #008000;
$color_orange: #F15922;
$color_darkorange: #565D64;
$color_red: #BE2424;