@import '../../css/vars.global.scss';

.outer {
    position: relative;
    transition: .3s ease-out;
    display: flex;

    &.left {
        width: fit-content;
        max-width: 980px;
        min-height: 223px;
        transform: translateX(-100%);
        opacity: 0;
    
        @media (max-width: 1500px) {
            max-width: 840px;
            min-height: 200px;
        }

        .inner {
            width: 100%;
            padding: 56px 80px 57px 140px;
    
            @media (max-width: 1500px) {
                padding: 48px 68px 48px 140px;
            }
        }

        &.active {
            transform: none;
            opacity: 1;
        }
    }
    
    &.center {
        max-width: 1358px;
        min-width: 1358px;
        width: 100%;
        
        justify-content: center;
        transform: scale(0.5);
        text-align: center;
        margin: 0 auto;
        opacity: 0;

        @media (max-width: 1500px) {
            max-width: 1160px;
            min-width: 1160px;
        }

        .inner {
            width: 100%;
            padding: 56px 80px;

            @media (max-width: 1500px) {
                padding: 48px 40px;
            }
        }

        &.active {
            transform: none;
            opacity: 1;
        }
    }

    .inner {
        background-color: $color_white;
    }
}

