@import '../../css/vars.global.scss';

.h1 {
    font-weight: 800;
    font-size: 52px;
    line-height: 110%;
    text-transform: uppercase;

    @media (max-width: 1500px) {
        font-size: 44px;
    }
}

.h2 {
    font-weight: 800;
    font-size: 44px;
    line-height: 120%;

    @media (max-width: 1500px) {
        font-size: 36px;
    }
}

.h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;

    @media (max-width: 1500px) {
        font-size: 28px;
    }
}

.bold {
    font-weight: 800;
}

.regular {
    font-weight: 500;
}

.margin-48 {
    margin-bottom: 48px;
}

.margin-40 {
    margin-bottom: 40px;

    @media (max-width: 1500px) {
        margin-bottom: 24px;
    }
}

.margin-36 {
    margin-bottom: 36px;
}

.margin-32 {
    margin-bottom: 32px;
}

.margin-28 {
    margin-bottom: 28px;

    @media (max-width: 1500px) {
        margin-bottom: 24px;
    }
}

.margin-24 {
    margin-bottom: 24px;
}

.margin-16 {
    margin-bottom: 16px;
}

.margin-0 {
    margin-bottom: 0;
}

.black {
    color: $color_black;
}

.green {
    color: $color_green;
}

.darkgreen {
    color: $color_darkgreen;
}

.titlegreen {
    color: $color_titlegreen;
}

.red {
    color: $color_red;
}

.orange {
    color: $color_orange;
}

.white {
    color: $color_white;
}