.list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.row {
        flex-direction: row;
        flex-wrap: wrap;

        li:first-child {
            flex-grow: 1;
        }
    }

    &.column {
        flex-direction: column;

        li {
            flex-basis: 100%;
        }
    }

    &.margin-24 {
        margin: -12px;

        li {
            margin: 12px;
        }
    }

    &.margin-20 {
        margin: -10px -10px 22px -10px;

        li {
            flex-basis: calc(100%/2 - 20px);
            margin: 10px;
        }
    }

    &.margin-16 {
        margin: -8px -8px 36px -8px;

        li {
            flex-basis: calc(100%/2 - 16px);
            margin: 8px;
        }
    }
}