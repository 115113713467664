.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 32px 0;
    align-items: center;

    @media (max-width: 1500px) {
        padding: 20px 0;
    }

    .logo {
        width: 81px;
        height: 50px;
    }
}