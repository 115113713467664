@import './vars.global.scss';

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Black.otf");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Black_Italic.otf");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Heavy.otf");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Heavy_Italic.otf");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_xBold.otf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_xBold_Italic.otf");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Bold.otf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Bold_Italic.otf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_SemiBold.otf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_SemiBold_Italic.otf");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Regular.otf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Regular_Italic.otf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Book.otf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Book.otf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Light.otf");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Light_Italic.otf");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Thin.otf");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Glober";
    src: url("../fonts/Glober_Thin_Italic.otf");
    font-weight: 100;
    font-style: italic;
}

* {
    box-sizing: border-box;
}

html {
    font-family: 'Glober', sans-serif;
    font-style: normal;
}

body {
    min-width: 1024px;
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

a {
    text-decoration: none;
}

button {
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

ul, ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

img, svg {
    width: 100%;
    pointer-events: none;
    user-select: none;
}

p {
    margin: 0;
}

.container {
    max-width: 1670px;
    margin: 0 auto;
    padding: 0 15px;

    @media (max-width: 1500px) {
        max-width: 1190px;
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100vw;
    background-color: $color_white;
}

main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 160px 0 40px;
    background: url('../assets/images/bg.png') no-repeat;
    background-size: cover;
    background-position: center center;

    img {
        height: 100%;
        object-fit: cover;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color_green;
        border-radius: 20px;
    }
}

.active {
    color: rgba(217, 78, 0, 1) !important;
}

.raw-html {
    margin-bottom: 32px;
    padding-left: 36px;

    @media (max-width: 1500px) {
        padding-left: 32px;
        margin-bottom: 24px;
    }

    li {
        font-size: 18px;
        line-height: 25px;
        color: $color_black;

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        @media (max-width: 1500px) {
            font-size: 16px;
            line-height: 19px;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        svg {
            position: absolute;
            left: 140px;
            width: 26px;
        }

        &::before {
            content: url('../assets/icons/star.svg');
            position: absolute;
            left: 140px;
            width: 26px;
            height: 26px;
            transform: translateY(-2px);
        }
    }
}