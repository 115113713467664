.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 20px;
    cursor: pointer;

    svg {
        pointer-events: none;
        transform: rotate(180deg);
    }
}