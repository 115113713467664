@import "../../../css/vars.global.scss";

.wrapper {
    margin-left: 24px;

    li {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        .points {
            padding: 20px 42px 16px 42px;
            background-color: $color_green;
            cursor: pointer;
            transition: 0.2s;
            &:not(:last-child) {
                margin-right: 4px;
            }
            &:hover {
                background-color: lighten($color_green, 10%);
            }

            &:global(.noActivity) {
                pointer-events: none;
            }
            &:global(.disabled) {
                pointer-events: none;
                background-color: $color_lightgrey;
            }
        }
    }
}
