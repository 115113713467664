@import '../../../css/vars.global.scss';

.list {
    display: flex;
    align-items: center;

    .item {
        font-weight: 400;
        font-size: 28px;
        line-height: 120%;
        color: $color_black;

        &:not(:last-child) {
            margin-right: 32px;
        }

        a {
            color: inherit;
        }

        @media (max-width: 1500px) {
            font-size: 24px;
        }
    }
}