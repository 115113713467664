@import '../../css/vars.global.scss';

@keyframes loader {
    0% {
        transform: scale(0.5);
        background-color: rgba(173, 231, 157, 1);
    }
    100% {
        transform: none;
        background-color: $color_titlegreen;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 8px 0;

    .item {
        width: 16px;
        height: 16px;
        margin: 0 6px;
        border-radius: 50%;
        animation: loader .8s linear infinite alternate;

        &:nth-child(2) {
            animation-delay: .3s;
        }

        &:nth-child(3) {
            animation-delay: .6s;
        }
    }
}