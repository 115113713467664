@import '../../css/vars.global.scss';

.item {
    margin-bottom: 64px;
    text-align: center;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .list {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 32px;

        &Item {
            max-width: 100px;
            width: 100%;
            padding: 20px 20px 16px 20px;
            background-color: $color_lightgrey;
            cursor: pointer;
            text-align: center;

            &.active {
                background-color: $color_green;
            }

            span {
                pointer-events: none;
            }
        }
    }

}

.btn {
    width: fit-content;
    margin: 0 auto;
}

.row {
    display: flex;
}

.bar {
    width: 240px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    .player {

        display: flex;
        align-items: center;

        &:not(:first-child){
            padding-top: 4px;
            margin-top: 4px;
            border-top: 1px solid lightgray;
        }
        .divider {
            display: block;
            flex-grow: 1;
        }
        .status {
            padding: 2px 4px;
            color: white;
            margin-left: 2px;
            background-color: red;
            // display: block;
            &.__sended {
                background-color: $color_green;
            }
        }
    }
}
.list {
    flex-grow: 1;
    .player {
        &:not(:first-child){
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid lightgray;
        }
        .player__name {
            font-size: 24px;
            text-align: left;
            margin-bottom: 24px;
        }
    }
}