@import '../../css/vars.global.scss';

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 414px;
    height: 67px;
    max-height: 67px;
    cursor: pointer;

    @media (max-width: 1500px) {
        width: 399px;
        height: 54px;
    }

    .left {
        width: calc(100% - 69px);
        height: 100%;
        padding: 16px 22px 14px 22px;
        background-color: $color_mgray;
        text-align: left;

        @media (max-width: 1500px) {
            width: calc(100% - 54px);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 69px;
        height: 100%;
        padding: 11px;
        text-align: center;
        background-color: $color_bg;

        @media (max-width: 1500px) {
            width: 54px;
        }

        svg {
            width: 32px;
        }
    }

    &:hover {
        .left {
            background-color: $color_gray;
        }
    }

    &:active {
        .left {
            background-color: $color_darkgray;
        }
    }

    &.disabled {
        pointer-events: none;

        .left {
            background-color: $color_lightgrey;
        }

        .right {
            svg {
                path {
                    stroke: $color_lightgrey;

                    &:nth-child(1) {
                        fill: $color_lightgrey;
                    }
                }
            }
        }
    }
}